<template>
  <div>
    <h1>Pagamentos</h1>
    <div v-if="loading" class="d-flex justify-content-center m-2">
      <b-spinner variant="primary" />
    </div>
    <template v-else-if="items.length > 0">
      <b-card no-body class="mb-0 m-2">
        <b-table
          :items="items"
          striped
          borderless
          responsive
          :fields="tableColumns"
          show-empty
          empty-text="Não foram encontrados pagamentos"
          :busy="loading"
        >
          <!-- Column: status -->
          <template #cell(status)="data">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{
                data.item.status_description
              }}</span>
            </div>
          </template>
          <!-- Column: installment -->
          <template #cell(installment)="data">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{
                data.item.installment
              }}</span>
            </div>
          </template>
          <!-- Column: amount -->
          <template #cell(amount)="data">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{
                data.item.amount | toCurrency
              }}</span>
            </div>
          </template>
          <!-- Column: expiration_date -->
          <template #cell(expiration_date)="data">
            <div class="text-wrap">
              <span
                v-if="data.item.payment_date"
                class="align-text-top text-capitalize"
                >{{ data.item.expiration_date | dateToStr }}</span
              >
            </div>
          </template>
          <!-- Column: reconciled_date -->
          <template #cell(reconciled_date)="data">
            <div class="text-wrap">
              <span
                v-if="data.item.reconciled_date"
                class="align-text-top text-capitalize"
                >{{ data.item.reconciled_date | dateTime }}</span
              >
            </div>
          </template>
          <!-- Column: reconciled_amount -->
          <template #cell(reconciled_amount)="data">
            <div class="text-wrap">
              <span
                v-if="data.item.reconciled_date"
                class="align-text-top text-capitalize"
                >{{ data.item.conciliator_amount | toCurrency }}</span
              >
            </div>
          </template>
          <template #cell(conciliator)="data">
            <div class="text-wrap">
              <span
                v-if="data.item.conciliator"
                class="align-text-top text-capitalize"
                >{{ data.item.conciliator }}</span
              >
            </div>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              right
              v-if="updateContractEnabled"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="remove(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Remover</span>
              </b-dropdown-item>
              <b-dropdown-item @click="reversal(data.item.id)">
                <feather-icon icon="RotateCcwIcon" />
                <span class="align-middle ml-50">Estornar comissões</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-card>
    </template>
    <template v-else>
      <span class="d-flex justify-content-center">
        Não foram encontrados pagamentos
      </span>
    </template>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { avatarText } from "@core/utils/filter";
import { UPDATE_ACTION, CONTRACT_RESOURCE } from "@/constants/resources";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        { key: "status", label: "Status" },
        { key: "installment", label: "Parcela" },
        { key: "amount", label: "Valor" },
        { key: "expiration_date", label: "Data prevista" },
        { key: "reconciled_date", label: "Data da reconciliação" },
        { key: "reconciled_amount", label: "Valor reconciliado" },
        { key: "conciliator", label: "Origem" },
        { key: "actions", label: "Ações" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      payments: types.PAYMENTS,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    items: function () {
      return this.payments || [];
    },
    updateContractEnabled: function () {
      return this.$can(UPDATE_ACTION, CONTRACT_RESOURCE);
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    ...mapActions({
      getPayments: types.GET_PAYMENTS,
      deletePayment: types.DELETE_PAYMENT,
      reversalPayment: types.REVERSAL_PAYMENT,
    }),
    get() {
      this.loading = true;
      this.getPayments({
        id: this.$route.params.id,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os pagamentos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    remove(idPayment) {
      this.$swal({
        title: "Tem certeza?",
        text: `Deseja remover o pagamento?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.deletePayment({ id: idPayment })
            .then((response) => {
              this.get();
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: `O pagamento foi removido`,
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
            })
            .catch((error) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: `Esse pagamento já foi faturado e não pode ser removido. Entre em contato com o setor de TI.`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    reversal(idPayment) {
      this.$swal({
        title: "Tem certeza?",
        text: `Deseja estornar as comissões do pagamento?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Estornar",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.reversalPayment({ id: idPayment })
            .then((response) => {
              this.get();
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: `As comissões foram estornadas com sucesso`,
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
            })
            .catch((error) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: `Ocorreu um erro ao estornar as comissões. Entre em contato com o setor de TI.`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
